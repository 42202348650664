<template>
  <div class="navMenu">
    <template v-for="(navMenu,index) in navMenus" >
      <el-submenu
        @click.native="pathTo(navMenu.url)"
       
        :key="index"
        :data="navMenu"
        :index="navMenu.label"
        v-if="navMenu.children && navMenu.children.length>0" 
      >
        <template slot="title">
          <i :class="navMenu.icon"></i>
          <span class="nav_item"> {{ navMenu.label }}</span>
        </template>
        <NavMenu :navMenus="navMenu.children"></NavMenu>
      </el-submenu>
            <el-menu-item
            v-else
        :key="index"
        :data="navMenu"
        :index="navMenu.url"
        :disabled='!navMenu.url'
      >
        <i :class="navMenu.icon"></i>
        <span slot="title">{{ navMenu.label }}</span>
      </el-menu-item>

    </template>
  </div>
</template>

<script>
export default {
  name: "NavMenu",
  props: ["navMenus"],
  data() {
    return {};
  },
  methods: {
    pathTo(path) {
      if (path) {
        this.$router.push(path);
      }
    },
  },
};
</script>
<style scoped>
    .nav_title{
    font-size: 17px;
font-family: PingFang SC-Bold, PingFang SC;
font-weight: bold;
color: #2E353E;

cursor: default;


}
.nav_item{
font-size: 17px;
font-family: PingFang SC-Bold, PingFang SC;
font-weight: bold;
color: #5D6978;
cursor: pointer;

}
:deep(.el-menu-item){
  font-size: 17px;
}
</style>