<template>
  <div>
    <el-container>
  <el-header  class="top_logo"> 
  
         <div class="logo"></div>
 
     </el-header>
     
     <el-row  class="container_layout" type="flex"  justify="space-between">
  <el-col :span="3"  class="left_menu">
   <el-aside >
     <el-menu
                    router
                    text-color="#2E353E"
                    active-text-color="#2E353E"
                    :default-active="$route.path"
                 :default-openeds="openeds"
                  >
                    <navMenu :navMenus.sync="menuList"></navMenu>
                  </el-menu></el-aside>
  </el-col>
  
                  <el-col :span="21">
     <el-main> <router-view></router-view></el-main>
                  </el-col>
     </el-row>

</el-container>


  </div>
</template>

<script>
import navMenu from './NavMenu/NavMenu.vue'
export default {
  name:'layout',

data(){
   return{
    CompanyName:null,
    CompanyCode:null,
    ElectricalProperty:null,
    BigIndustryType:null,
    SmallIndustryType:null,
    ProvinceId:0,
    CityId:0,
    CountyId:0,
    remark:'',
    activeIndex:'/',
    companyList:[],
    menuList:[
    {
    label:'服务',
    value:0,
    icon:'el-icon-copy-document',
   children:[
    { 
    label:'响应申报',
    value:1,
    icon:'el-icon-notebook-2',
    url:'/demandResponse',
     children:[]

  }
   ]
    },
 {
    label:'我的',
    value:2,
    icon:'el-icon-user',
    children:[
            { 
        label:'企业信息',
        value:3,
        icon:'el-icon-office-building',
        url:'/information',
        children:[]
      },
      //   { 
      //   label:'绑定微信',
      //   icon:'el-icon-user',
      //   value:4,
      //    children:[]
      // },
    ]
    },
 
    ],
    openeds:['服务','我的']
            }
     },
       watch: {
    $route () {
      this.currentRoute()
    }
  },
      async created (){
        console.log(navigator.userAgent)
    this.currentRoute()
    await this.$axiosMsg()

                this.CompanyName = this.$store.getters.GETCompanyName
                this.CompanyCode = this.$store.getters.GETCompanyCode
                this.ElectricalProperty = this.$store.getters.GETElectricalProperty
                this.BigIndustryType = this.$store.getters.GETBigIndustryType
                this.SmallIndustryType = this.$store.getters.GETSmallIndustryType
                this.ProvinceId = this.$store.getters.GETProvinceId
                this.CityId = this.$store.getters.GETCityId
                this.CountyId = this.$store.getters.GETCountyId
                this.remark = this.$store.getters.GETremark

                this.getCompany()
      },
      mounted(){
      

                this.getCompany()
      },
      methods:{
       currentRoute () {
 this.activeIndex =this.$route.path
 
    console.log(this.activeIndex)
    },
       //获取企业列表
  getCompany(){
  
    this.$axiosPost('/api/ResponseDemand/GetReponseCompanyList').then(res=>{

      if(res.Tag==1){
        this.companyList=res.Data
      }
    })

  },
  //选择
  chooseMenu(e){

    localStorage.setItem('nav',e.value)
    this.$router.push(e.url)

  }
      },
beforeRouteEnter(to, from, next) {
// 添加背景色
 document.querySelector('body').setAttribute('style', 'background-color:#eaeff1')
next()

},

beforeRouteLeave(to, from, next) {

 // 去除背景色
 document.querySelector('body').setAttribute('style', '')

 next()
 },
   components: {
     navMenu

  }





}
</script>

<style lang="scss" scoped>
body{
    background: #dae0e2;
  
}
:deep(.el-menu){
border: none;
}


:deep(.el-main){
  padding: 0;
}


:deep(.el-aside){
  width: 100% !important;
 height: 100%;
}
.top_logo{
 width: 100%;
height: 89px !important;
background: linear-gradient(90deg, #22CEC9 0%, #4865E5 100%);
border-radius: 0px 0px 0px 0px;
opacity: 1;
font-size: 16px;
display: flex;
justify-content: flex-start;
align-items: center;
margin-bottom: 20px;
.logo{
    width: 250px;
    height: 100%;
    margin-left: 25px;
    background: url('../../assets/logo2.png') no-repeat;
    background-size: 100% 100%;
background-position: 5% 0;
// margin-top: 20px;
}
}
.container_layout{
    display: flex;
    padding-right: 20px;
    // align-items: stretch;
.left_menu{
// width: 224px;
 background: #FFFFFF;
 margin-right: 20px;

 color: #2E353E;
 position: relative;
 .menu{
   padding-bottom: 220px;
 }
.left_menu_logo{
    width: 100%;
    height: 204px;
    background:url('../../assets/nav_bottom.png') no-repeat 100% 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    
}
.menuList{
    width: 100%;


}


}
}

.nav_item_after{
    background:rgba(73,143,251,.5)
}

.line{
    width: 100%;
    height: 1px;
    background: #DDE2E6;
    margin: 30px 0;
}
</style>

